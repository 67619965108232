import request from '@/utils/request'

// 用户信息统计
export function getUserStatistics(data) {
    return request({
        url: '/get_user_statistics',
        method: 'post',
        data,
    })
}

export function getMeasureStatistics(data) {
    return request({
        url: '/get_measure_statistics',
        method: 'post',
        data,
    })
}